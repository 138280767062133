
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.HomeBanner {
  position: relative;
  z-index: 2;
  background-color: var(--color-back);
  width: 100vw;

}

.HomeBanner__wrapper {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle,rgba(3, 29, 48, 0) 0%,rgba(3, 29, 48, 1) 82%),url('../../../img/bg-gamepad.webp');
  background-size: 71%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 2500px;
  margin: 0 auto;
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    background: linear-gradient(180deg,rgba(3, 29, 48, 0) 0%,rgba(3, 29, 48, 1) 82%);
    width: 100%;
    height: 20%;
  }

  @include medium {
    background-size: 130%;
  }

}

.HomeBanner__title {
  color: whitesmoke;
  text-align: center;
  font-size: 30px;
  z-index: 500;
  margin: 3rem 0;
  padding: 0 10px;
}

.mobileApp {
  display: flex;
  gap: 20px; /* Optional: adds space between columns */

  .element {
    flex: 1;
    padding: 1rem;
  }
}