
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.content {
  max-width: 900px;
  min-width: 900px;
  background: var(--color-text-light);
  padding: 3rem 1rem 1rem 1rem;
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: 2rem;
  @include fadeIn;

  @include medium {
    margin-top: 0;
    min-width: unset;
    margin: 0 auto;
    margin-bottom: 2rem;
    padding: 3rem 0.5rem 1rem 0.5rem;
  }



  &__title {
    color: var(--color-text);
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 28px;

    @include small {
      font-size: 25px;
    }
  }

  &__subtitle {
    text-align: center;
    line-height: 15px;
    width: 58%;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 600;

    @include small {
      width: 89%;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;

    @include Xsmall {
      grid-template-columns: 1fr;
    }

    &-loader {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &__search-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  &__no-result {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {
      text-align: center;
      margin: 2rem 0;
    }
  }

  &__game-title {
    color: var(--color-text);
    font-size: 16px;
    margin: 1rem 0;
    padding-left: 10px;
  }


}